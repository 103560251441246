import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';

import { api } from '../../../utils/apiRequest';
import {
  getValueFromLocalStorage,
  setDataAtKeyInNestedObject,
  validateDataBasedOnConfig,
} from '../../../utils/common';
import { updateCustomerDetails } from '../../Order/CreateOrder/reducer';
import { PlusIcon } from '../../../assets/icons';
import { Button, Loader, Modal, ModalMethods, Text, toasts } from '../../../ui-component';

import { CustomerDetailsErrorType, CustomerDetailsType, listDataType } from '../types';

import Details from './components/Details';
import Card from './components/Card';

import { Container, ContentContainer, Header, ListContainer } from './style';
import { validateCustomerDetailsObject } from './constant';
import { FaSearch } from 'react-icons/fa';

const CustomerList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createCustomerRef = useRef<ModalMethods>(null);

  const [listData, setListData] = useState<listDataType[]>([]);
  const [isLoading, setIsloading] = useState(false);

  const [data, setData] = useState<CustomerDetailsType>();
  const [errorData, setErrorData] = useState<CustomerDetailsErrorType>();

  useEffect(() => {
    void getListData();
  }, []);

  const getListData = async (searchQuery?: string) => {
    const boutique_id = getValueFromLocalStorage('boutique_id');

    if (!_isNil(boutique_id)) {
      setIsloading(true);

      try {
        let response;

        if (searchQuery) {
          response = await api.postRequest('search', {
            boutique_id: boutique_id,
            target_entity: 'customer',
            search_key: searchQuery,
          });
          // Set search results from customer_details array

          if (response.status) {
            setListData(response.data.customer_details || []);
          }
        } else {
          response = await api.getRequest(`customers/?boutique_id=${boutique_id}`);
          // Set regular list from boutique_customer_details

          if (response.status) {
            setListData(
              !_isNil(response.data.boutique_customer_details)
                ? response.data.boutique_customer_details
                : []
            );
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          toasts('error', err.message, 'error-cutomer-list');
        }
      }
      setIsloading(false);
    } else {
      toasts('info', 'Boutique Not Exist', 'boutique-not-exists');
    }
  };
  const handleCardClick = (selectedCustomer: listDataType) => {
    if (location.pathname.includes('select-customer')) {
      dispatch(updateCustomerDetails({ data: selectedCustomer }));
      navigate(`/select-outfit`);
    }

    if (location.pathname.includes('customers')) {
      dispatch(updateCustomerDetails({ data: selectedCustomer }));
      // eslint-disable-next-line no-console
      console.log('customer', selectedCustomer);
      navigate(`/selectcustomer`);
    }
  };

  const onModalSuccess = async () => {
    const boutique_id = getValueFromLocalStorage('boutique_id');

    const { isValid, errorData } = validateDataBasedOnConfig(validateCustomerDetailsObject, {
      ...data,
      boutique_id,
    });

    if (!isValid) {
      setErrorData(errorData as CustomerDetailsErrorType);
    }

    if (!_isUndefined(data) && !_isNil(boutique_id) && isValid) {
      const { customer_image_urls, ...rest } = data;

      const formattedData = {
        ...rest,
        age: +rest.age,
        phone_number: +rest.phone_number,
        customer_image_reference_id: customer_image_urls?.reference_id ?? '',
        boutique_id,
      };

      try {
        setIsloading(true);

        const response = await api.postRequest('customers/', formattedData);

        const { status } = response;

        if (status) {
          resetForm();
          createCustomerRef.current?.hide();
          void getListData();
        }
      } catch (err) {
        if (err instanceof Error) {
          toasts('error', err.message, 'create-customer-error');
        }
      }

      setIsloading(false);
    }
  };

  const handleChange = (objList: Array<{ value: any; key: string }>) => {
    let updatedData = structuredClone(data);

    objList.forEach((currObj) => {
      const { value, key } = currObj;
      updatedData = setDataAtKeyInNestedObject(updatedData, key, value) as CustomerDetailsType;
    });

    setData(updatedData);
  };

  const resetForm = () => {
    setData(undefined);
    setErrorData(undefined);
  };
  //eslint-disable-next-line no-console
  //console.log(data);
  // eslint-disable-next-line no-console
  //console.log(listData);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);

    // Debounce or minimum length check could be added here
    if (newQuery.length > 0) {
      void getListData(newQuery);
    } else if (newQuery.length === 0) {
      void getListData(); // Reset to full list when search is cleared
    }
  };

  return (
    <Container>
      <Header>
        <div className="relative w-[35%]">
          <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
            <FaSearch className="h-4 w-4 text-gray-500" />
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder="Search by name or number"
            className="w-full py-2 pl-10 pr-4 bg-gray-50 font-inter font-[500] rounded-lg text-sm outline-none focus:ring-2 focus:ring-gray-200 border-none placeholder:text-gray-500"
          />
        </div>

        <Button
          size="small"
          leadingIcon={<PlusIcon />}
          onClick={() => navigate('/addcustomer')}
          // eslint-disable-next-line no-console
          //onClick={() => createCustomerRef.current?.show()} delete popup component
        >
          Add Customer
        </Button>
      </Header>
      <ContentContainer>
        <Text fontWeight={500}>Select Customer ({listData.length})</Text>
        <ListContainer>
          {listData.map((data) => (
            <Card key={data.customer_id} data={data} onClick={handleCardClick} />
          ))}
        </ListContainer>
      </ContentContainer>

      <Modal
        ref={createCustomerRef}
        title="Add new customer"
        onModalClose={() => {
          createCustomerRef.current?.hide();
          resetForm();
        }}
        onModalSuccess={onModalSuccess}
        saveButtonText="Save & Continue"
      >
        <Details
          data={data}
          errorData={errorData}
          setErrorData={setErrorData}
          handleChange={handleChange}
          setIsloading={setIsloading}
        />
      </Modal>
      <Loader showLoader={isLoading} />
    </Container>
  );
};

export default CustomerList;
