import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _isNil from 'lodash/isNil';
import { BiSolidDownload } from 'react-icons/bi';

import { api } from '../../../../../utils/apiRequest';
import {
  convertNumberOrStringToPriceFormat,
  getValueFromLocalStorage,
  handleDownloadInvoice,
} from '../../../../../utils/common';
import { positiveNumberRegex } from '../../../../../utils/regexValue';
import { moneyFormatSigns } from '../../../../../utils/contant';

import { DropdownField, InputField } from '../../../../../components/FormComponents';
import { Button, Modal, ModalMethods, Text, toasts } from '../../../../../ui-component';
import type { OrderPriceBreakupType } from '../../../CreateOrder/type';

import { paymentModeOptions } from './constant';
import {
  InvoiceSummaryContainer,
  InvoiceSummaryContent,
  ModalBody,
  PaymentOptionItem,
} from './style';
import { RiWhatsappFill } from 'react-icons/ri';
import transtion from '../../../../../assets/images/money.png';
import transtion1 from '../../../../../assets/images/money1.png';
import print from '../../../../../assets/images/print.png';
import { MdDeleteOutline } from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';

type InvoiceSummaryProps = {
  order_id: number;
  orderStatus: string;
  setIsLoading: (data: boolean) => void;
};
type PaymentSummary = {
  amount: number;
  id: number;
  paymentDate: string;
  paymentMode: number;
  createdAt: string;
};

const InvoiceSummary = ({ order_id, orderStatus, setIsLoading }: InvoiceSummaryProps) => {
  const ref = useRef<ModalMethods>(null);
  const editModalRef = useRef<ModalMethods>(null);
  const navigate = useNavigate();

  const [invoiceLink, setInvoiceLink] = useState('');
  const [data, setData] = useState<Record<string, any>>();

  const [recieveAmount, setRecieveAmount] = useState<string | undefined>();
  const [err, setErr] = useState<string>();
  const [paymentMethod, setPaymentMethod] = useState<Record<string, any>>(paymentModeOptions[0]);

  const [selectedPaymentIds, setSelectedPaymentIds] = useState<number[]>([]);

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  // Update the print button click handler
  const handlePrint = () => {
    if (reactToPrintFn) {
      reactToPrintFn();
    }
  };

  useEffect(() => {
    if (!_isNil(order_id)) {
      void getInvoiceLink();
      void getInvoiceDetails();
    }
  }, [order_id]);

  const getInvoiceLink = async () => {
    try {
      const boutique_id = getValueFromLocalStorage('boutique_id');

      const payload = {
        entity_type: 'invoice',
        entity_id: order_id,
        meta_data: {
          boutique_id,
        },
      };

      setIsLoading(true);
      const response = await api.postRequest(`storage/file/link`, payload);

      const { status, data } = response;

      if (status) {
        setInvoiceLink(data.link);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'get-invoice-link-error');
      }
    }

    setIsLoading(false);
  };

  const getInvoiceDetails = async () => {
    try {
      setIsLoading(true);
      const boutique_id = getValueFromLocalStorage('boutique_id');

      const response = await api.getRequest(
        `order/${order_id}/invoice_detail?boutique_id=${boutique_id}`
      );

      const { status, data } = response;

      if (status) {
        setData(data);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'get-invoice-summary-error');
      }
    }

    setIsLoading(false);
  };

  const handleChange = (value: string, errMsg?: string) => {
    setRecieveAmount(value);
    setErr(errMsg);
  };

  const handleConfirmBtn = () => {
    if (!_isNil(err) && !_isNil(recieveAmount)) {
      ref.current?.show();
    } else {
      toasts('error', 'Please fill correct amount', 'amount-error');
    }
  };

  const handleModalClose = () => {
    ref.current?.hide();
  };

  const handleModalSave = async () => {
    try {
      const payload = {
        payment_mode: paymentMethod.value,
        payment_date: moment(new Date()).format('YYYY/DD/MM'),
        amount: parseFloat(recieveAmount ?? '0'),
      };
      const boutique_id = getValueFromLocalStorage('boutique_id');
      setIsLoading(true);

      const response = await api.postRequest(
        `order/${order_id}/recieve_payment?boutique_id=${boutique_id}`,
        payload
      );
      const { status } = response;

      if (status) {
        ref.current?.hide();

        void getInvoiceDetails();
        setRecieveAmount('');
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts('error', err.message, 'recieve-payment-err');
      }
    }

    setIsLoading(false);
  };

  const CustomOption = (props: any) => {
    const { innerProps, innerRef, isSelected } = props;

    return (
      <PaymentOptionItem $isSelected={isSelected as boolean} ref={innerRef} {...innerProps}>
        <div className="img">{props.data.icon}</div>
        <div className="revision-text">
          <Text color={isSelected ? 'white' : 'black'} fontWeight={600}>
            {props.data.label}
          </Text>
        </div>
      </PaymentOptionItem>
    );
  };

  const handleSelectPayment = (value: any) => {
    setPaymentMethod(value);
  };

  //eslint-disable-next-line
  // const handleShare = (link: string) => {
  //   // const whatsappURL = `whatsapp://send?text=${encodeURIComponent(link)}`;
  //   // window.location.href = whatsappURL;

  //   const customerPhone = data?.customer_phone_number?.replace(/\D/g, ''); // Remove non-digits
  //   // eslint-disable-next-line no-console
  //   //console.log(customerPhone);
  //   const whatsappURL = customerPhone
  //     ? `https://wa.me/${customerPhone}?text=${encodeURIComponent(link)}`
  //     : `https://wa.me/?text=${encodeURIComponent(link)}`;
  //   window.open(whatsappURL, '_blank');
  // };

  const [isSharing, setIsSharing] = useState(false);

  const handleShare = async (link: string) => {
    try {
      setIsSharing(true); // Show loader
      const response = await fetch(link);
      const blob = await response.blob();
      const file = new File([blob], `${data?.order_summary.boutique_order_id ?? ''}_invoice.pdf`, {
        type: 'application/pdf',
      });

      const customerPhone = data?.customer_phone_number?.replace(/\D/g, '');

      if (navigator.share && file) {
        await navigator.share({
          files: [file],
          title: 'Invoice',
          text: 'Here is your invoice',
        });
      } else {
        const whatsappURL = customerPhone ? `https://wa.me/${customerPhone}` : `https://wa.me/`;
        window.open(whatsappURL, '_blank');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error sharing file:', error);
    } finally {
      setIsSharing(false); // Hide loader
    }
  };

  const handleEditButton = () => {
    navigate(`/orders/${order_id}?formType=edit`);
  };

  if (_isNil(data)) {
    return null;
  }

  // eslint-disable-next-line no-console
  console.log(data);

  const handleDeleteTransaction = (paymentId: number) => {
    // Use callback form of setState to ensure we have latest state
    setSelectedPaymentIds((prevIds) => {
      const newIds = [...prevIds, paymentId];
      // eslint-disable-next-line no-console
      console.log(newIds); // Log the updated array here

      return newIds;
    });

    // Rest of the function remains same
    const updatedPaymentList = data?.order_summary?.payment_summary_response_list.filter(
      (payment: PaymentSummary) => payment.id !== paymentId
    );

    setData({
      ...data,
      order_summary: {
        ...data.order_summary,
        payment_summary_response_list: updatedPaymentList,
      },
    });
  };

  return (
    <InvoiceSummaryContainer>
      <div className="invoice-header">
        <Text fontWeight={500}> Order Details</Text>
        <div className="header-btn-container">
          {orderStatus === 'Drafted' && (
            <Button appearance="outlined" onClick={handleEditButton}>
              Edit
            </Button>
          )}
          <Button
            appearance="outlined"
            className="flex items-center gap-2"
            onClick={() => editModalRef.current?.show()}
          >
            <p className=" font-inter font-[600]">Edit</p>
            <img src={transtion1} alt="tran" className="size-5" />
          </Button>

          <Button
            appearance="outlined"
            onClick={() => {
              handleDownloadInvoice(
                invoiceLink,
                `${data?.order_summary.boutique_order_id ?? ''}_invoice.pdf`
              );

              //   window.open(invoiceLink, '_blank');
            }}
            trailingIcon={<BiSolidDownload color="var(--color-primary)" size={16} />}
          >
            <p className=" font-inter font-[600]"> Download pdf</p>
          </Button>
          <Button onClick={handlePrint} appearance="outlined" className=" flex gap-2 items-center">
            <p className=" font-inter font-[600]"> Print</p>
            <img src={print} alt="tran" className="size-5" />
          </Button>
          <p onClick={handlePrint} className=" hidden"></p>
          <Button
            onClick={() => handleShare(invoiceLink)}
            appearance="outlined"
            className="flex gap-2"
            disabled={isSharing}
          >
            <p className="font-inter font-[600]">{isSharing ? 'Preparing...' : 'send bill'}</p>
            {isSharing ? (
              <div className="w-5 h-5 border-2 border-green-600 border-t-transparent rounded-full animate-spin" />
            ) : (
              <RiWhatsappFill className="text-green-600 text-[1.2rem] cursor-pointer" />
            )}
          </Button>
        </div>
      </div>
      <InvoiceSummaryContent ref={contentRef}>
        <div className="content-container">
          <div>
            <Text fontWeight={600} size="xxxl">
              Invoice
            </Text>
            <Text fontWeight={700}>{data?.boutique_name ?? '-'}</Text>
          </div>
          <div className="invoice-right-info">
            <div className="invoice-info-text">
              <Text fontWeight={600}>Invoice Date:</Text>
              <Text fontWeight={500} color="black">
                {!_isNil(data?.invoice_date_time)
                  ? new Date(data?.invoice_date_time).toDateString()
                  : '-'}
              </Text>
            </div>
            <div className="invoice-info-text">
              <Text fontWeight={600}>Invoice Number:</Text>
              <Text fontWeight={500} color="black">
                {data?.order_summary.invoice_no}
              </Text>
            </div>
          </div>
        </div>

        <div className=" w-full flex justify-between p-[2rem] ">
          <div className="customer-info ">
            <div className="customer-info-text">
              <Text fontWeight={600}>Customer Name:</Text>
              <Text fontWeight={500} color="black">
                {data?.customer_name ?? '-'}
              </Text>
            </div>
            <div className="customer-info-text">
              <Text fontWeight={600}>Order Number:</Text>
              <Text fontWeight={500} color="black">
                {data?.order_summary.boutique_order_id ?? '-'}
              </Text>
            </div>
            <div className="customer-info-text">
              <Text fontWeight={600}>Order Received Date:</Text>
              <Text fontWeight={500} color="black">
                {!_isNil(data?.recieve_date_time)
                  ? new Date(data?.recieve_date_time).toDateString()
                  : '-'}
              </Text>
            </div>
            {/* <div className="customer-info-text">
              <Text fontWeight={600}>Payment Mode:</Text>
              <Text fontWeight={500} color="black">
                {paymentModeOptions.find((option) => option.value === (data?.payment_mode || 1))
                  ?.label ?? '-'}
              </Text>
            </div> */}
            <div className=" w-[100%] mt-[3rem] ">
              <div className="flex items-center gap-2">
                <Text fontWeight={700} size="medium" color="#525252">
                  Transactions
                </Text>
                <img src={transtion} alt="tran" className="size-5" />
              </div>

              {data?.order_summary?.payment_summary_response_list?.map(
                (payment: PaymentSummary, index: number) => (
                  <div key={index} className="w-full ">
                    <div className="w-full flex gap-2 ">
                      <Text fontWeight={600} size="small">
                        {moment(payment.createdAt).format('Do MMM YYYY, h:mm A')}
                      </Text>
                      <div className="flex gap-1">
                        <Text fontWeight={600} size="small" color="#30BC00">
                          ₹ {payment.amount}
                        </Text>
                        <Text fontWeight={600} size="small">
                          received via
                        </Text>
                      </div>
                      <Text fontWeight={600} size="small" color="#323233" className="">
                        {paymentModeOptions.find(
                          (option) => option.value === (payment.paymentMode || 1)
                        )?.label ?? '-'}
                      </Text>
                    </div>
                  </div>
                )
              )}
            </div>
            {/* <div className="w-full flex gap-2 items-center mt-[2rem]">
              <Text fontWeight={600}>Last Updated:</Text>
              <Text fontWeight={600}>26 Jan 2022 / 3:30 Pm</Text>
            </div> */}
          </div>

          <div className="amount-summary-container">
            <div className="outfit-summary-amount-box">
              <Text size="large" fontWeight={600}>
                Order Summary
              </Text>
              <div className="outfit-price-list">
                {data?.order_summary.order_item_summary_list.map((itemObj: any, index: number) => {
                  const total =
                    itemObj?.price_breakup?.reduce(
                      (sum: number, currPriceObj: OrderPriceBreakupType) =>
                        sum + (currPriceObj?.value ?? 0) * currPriceObj.component_quantity,
                      0
                    ) ?? 0;

                  return (
                    <div key={index}>
                      <div className="amount-item">
                        <Text color="black" fontWeight={700}>
                          {itemObj.outfit_alias}
                        </Text>
                        <Text color="black" fontWeight={700}>
                          {`${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                            total ?? 0
                          )}`}
                        </Text>
                      </div>

                      <div className="price-breakup-list">
                        {itemObj?.price_breakup?.map(
                          (priceObj: OrderPriceBreakupType, index: number) => (
                            <div key={index} className="amount-item">
                              <Text size="small" color="black" fontWeight={500}>
                                {priceObj.component}
                              </Text>
                              <Text size="small" color="black" fontWeight={500}>
                                {`${priceObj.component_quantity} x ${priceObj.value} = `}
                                {` ${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                                  priceObj.component_quantity * (priceObj?.value ?? 0)
                                )}`}
                              </Text>
                            </div>
                          )
                        )}
                      </div>

                      <Text size="small" fontWeight={500} className="date-field-style">
                        {`Delivery Date: ${new Date(itemObj.delivery_date).toDateString()}`}{' '}
                      </Text>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="amount-box">
              <div className="amount-item">
                <Text color="black" fontWeight={600}>
                  Total:
                </Text>
                <Text color="black" fontWeight={600}>
                  {`${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                    data?.order_summary?.total_order_amount ?? 0
                  )}`}
                </Text>
              </div>
            </div>

            <div className="amount-box">
              <div className="amount-item">
                <Text color="black" fontWeight={600}>
                  Advance (if any):
                </Text>
                <Text color="black" fontWeight={600}>
                  {`${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                    data?.order_summary?.order_advance_recieved ?? 0
                  )}`}
                </Text>
              </div>
            </div>

            <div className="amount-box">
              <div className="amount-item">
                <Text color="black" fontWeight={600}>
                  Balance Due:
                </Text>
                <Text fontWeight={600} color="tertiary">
                  {`${moneyFormatSigns.rupee} ${convertNumberOrStringToPriceFormat(
                    (data?.order_summary?.total_order_amount ?? 0) -
                      (data?.order_summary?.order_advance_recieved ?? 0)
                  )}`}
                </Text>
              </div>
            </div>

            {data?.order_summary?.total_order_amount !==
              data?.order_summary?.order_advance_recieved &&
              orderStatus !== 'Drafted' && (
                <>
                  <div className="advance-amount-box">
                    <Text color="black" fontWeight={600}>
                      Recieve Balance Due
                    </Text>
                    <InputField
                      type="text"
                      required={false}
                      value={recieveAmount}
                      placeholder="0"
                      className="amount-field"
                      regex={positiveNumberRegex}
                      onChange={(value, errorMsg) => handleChange(value, errorMsg)}
                    />
                  </div>
                  <div className="btn-container">
                    <Button onClick={handleConfirmBtn}>Confirm</Button>
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="footer-content">
          <Text size="xxxl" fontWeight={600}>
            Thank You for your business
          </Text>
          <Text fontWeight={600}>Terms and conditions</Text>
          <Text fontWeight={500}>
            *Once confirmed, any advance amount paid towards the order cannot be refunded.
          </Text>
          <Text fontWeight={500}>*Goods once sold will not be taken back.</Text>
          <Text fontWeight={500}>
            *We do not give a guarantee of colors. We advise dry cleaning only.
          </Text>
        </div>
      </InvoiceSummaryContent>

      <Modal
        ref={ref}
        size="medium"
        title="Select mode of payment"
        onModalClose={handleModalClose}
        onModalSuccess={handleModalSave}
      >
        <ModalBody>
          <DropdownField
            label=""
            options={paymentModeOptions}
            value={
              !_isNil(paymentMethod)
                ? { label: paymentMethod.label, value: paymentMethod.value }
                : undefined
            }
            onChange={handleSelectPayment}
            CustomOption={CustomOption}
          />
        </ModalBody>
      </Modal>
      <Modal
        ref={editModalRef}
        size="medium"
        title="Delete Transactions            "
        onModalClose={() => {
          editModalRef.current?.hide();
          void getInvoiceDetails();
        }}
        onModalSuccess={async () => {
          try {
            //const boutique_id = getValueFromLocalStorage('boutique_id');
            // eslint-disable-next-line no-console
            console.log(selectedPaymentIds);
            setIsLoading(true);

            const response = await api.postRequest(`order/${order_id}/delete_payment`, {
              payment_ids: selectedPaymentIds,
            });

            if (response.status) {
              toasts('success', 'Transactions deleted successfully', 'delete-transaction-success');
              void getInvoiceDetails();
              setSelectedPaymentIds([]);
            }
          } catch (err) {
            if (err instanceof Error) {
              toasts('error', err.message, 'delete-transaction-error');
            }
          }
          setIsLoading(false);
          editModalRef.current?.hide();
        }}
      >
        <ModalBody>
          <div>
            <div className="space-y-4">
              <div className="grid grid-cols-4 text-sm font-medium text-muted-foreground">
                <div>Transaction Date</div>
                <div className="flex items-center justify-center">Amount</div>
                <div>Payment Mode</div>
                <div></div>
              </div>
              {data?.order_summary?.payment_summary_response_list.map(
                (payment: PaymentSummary, index: number) => (
                  <div key={index} className="grid grid-cols-4 items-center text-sm">
                    <Text fontWeight={600} size="small">
                      {moment(payment.createdAt).format('Do MMM YYYY, h:mm A')}
                    </Text>
                    <Text
                      fontWeight={600}
                      size="small"
                      color="#30BC00"
                      className="flex items-center justify-center"
                    >
                      ₹ {payment.amount}
                    </Text>
                    <Text fontWeight={600} size="small" className="">
                      {paymentModeOptions.find(
                        (option) => option.value === (payment.paymentMode || 1)
                      )?.label ?? '-'}
                    </Text>
                    <button
                      className="text-red-500 hover:text-red-600 hover:bg-red-100 rounded-md border border-slate-300  px-[2px] py-[2.6px] bg-white flex items-center justify-center gap-2 w-[70%]"
                      onClick={() => handleDeleteTransaction(payment.id)}
                    >
                      <MdDeleteOutline /> Delete
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </InvoiceSummaryContainer>
  );
};

export default InvoiceSummary;
