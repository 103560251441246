// import { httpStatusCodes } from './contant';
import _isEmpty from 'lodash/isEmpty';

import { toasts } from '../ui-component';
//import { getValueFromLocalStorage } from './common';
import { auth } from '../firbase/index';

// const { fetch: originalFetch } = window;

// window.fetch = async (...args) => {
//   const [resource, config] = args;
//   // request interceptor starts
//   // resource = 'https://jsonplaceholder.typicode.com/todos/2';
//   const controller = new AbortController();
//   //   const timeoutId = setTimeout(() => controller.abort(), 300000);
//   let updatedConfig = {
//     ...config,
//     headers: {
//       ...config?.headers,
//       //   Authorization: getValueFromLocalStorage('accessToken') ?? '',
//     },
//     signal: controller.signal,
//   };

//   //   if (process.env.NODE_ENV === 'development') {
//   //     updatedConfig = {
//   //       ...updatedConfig,
//   //       headers: {
//   //         ...config?.headers,

//   //         frontEnv: 'local',
//   //       },
//   //     };
//   //   }

//   // request interceptor ends
//   // console.log('Config:', config);

//   await originalFetch(resource, updatedConfig);

//   // response interceptor here
//   // if(response.status === httpStatusCodes.UNAUTHORIZED){
//   //

//   return Promise.reject('Logging Out');
// };

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const makeApiRequest = async (url: string, config: any) => {
  try {
    //const token = getValueFromLocalStorage('token');
    const token = await auth.currentUser?.getIdToken();
    // eslint-disable-next-line no-console
    //console.log(token);
    // eslint-disable-next-line no-console
    //console.log(url);
    const updatedConfig = {
      ...config,
      headers: {
        ...config.headers,
      },
    };

    if (!_isEmpty(token)) {
      updatedConfig.headers = {
        ...updatedConfig.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    // eslint-disable-next-line no-console
    //console.log(updatedConfig);

    const response = await fetch(baseUrl + url, updatedConfig);
    const { status, statusText } = response;
    const data = await response.json();

    // eslint-disable-next-line no-console
    //console.log(response);

    if (status >= 100 && status < 200) {
      //   if (isEmpty(this.formName)) {
      //     updateApiErrorReducer({ [this.formName]: data?.message || statusText }, this.dispatch);
      //   }
    } else if (status >= 200 && status < 300) {
      toasts('success', data.message, 'success');
      //   if (data?.isMessageRequired) toast.success(data?.message);
    } else if (status >= 300 && status < 400) {
      //Nothing
    } else if (status === 401) {
      //   this.dispatch(logoutRequest());
    } else if (status === 403) {
      //   this.navigate('/403');
      // } else if (status === 404) {
      //   this.navigate("/404");
    } else if (status >= 400) {
      toasts('error', data?.message || statusText, 'api-error');
    }

    const responseData = {
      data: Array.isArray(data)
        ? data
        : Object.fromEntries(Object.entries(data).filter(([key]) => key !== 'message')),
      status: status >= 200 && status < 300,
      message: data?.message || '',
    };

    return responseData;
  } catch (error: any) {
    if (error instanceof Error) {
      toasts('error', error.message, 'api-error');
    }

    return error;
  }
};

const getRequest = (url: string, headers: Record<string, any> = {}) => {
  const config = {
    method: 'GET',
    headers,
  };

  return makeApiRequest(url, config);
};

const postRequest = (
  url: string,
  params: any = {},
  isFormData = false,
  headers: Record<string, any> = {}
) => {
  const config = {
    method: 'POST',
    headers: isFormData
      ? {
          ...headers,
          //   'Content-Type': 'multipart/form-data',
        }
      : {
          ...headers,
          'Content-Type': 'application/json',
        },

    body: isFormData ? params : JSON.stringify(params),
  };

  return makeApiRequest(url, config);
};

const putRequest = (
  url: string,
  params: Record<string, any> = {},
  isFormData = false,
  headers: Record<string, any> = {}
) => {
  const config = {
    method: 'PUT',
    headers: isFormData
      ? {
          ...headers,
          //   'Content-Type': 'multipart/form-data',
        }
      : {
          ...headers,
          'Content-Type': 'application/json',
        },

    body: isFormData ? params : JSON.stringify(params),
  };

  return makeApiRequest(url, config);
};

const patchRequest = (
  url: string,
  params: Record<string, any> = {},
  headers: Record<string, any> = {}
) => {
  const config = {
    method: 'PATCH',
    headers,
    body: params,
  };

  return makeApiRequest(url, config);
};

const deleteRequest = (
  url: string,
  params: Record<string, any> = {},
  headers: Record<string, any> = {}
) => {
  const config = {
    method: 'DELETE',
    headers,
    body: params,
  };

  return makeApiRequest(url, config);
};

export const api = {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
};
